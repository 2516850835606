
<template v-if="visible">
  <router-view />
</template>
<script>
import settingsService from './security/auth/settings.service';
export default {
  data() {
    return {
      visible: false
    }
  },

  mounted() {
    if (localStorage.getItem('uid')) {
      settingsService.setUsuario(JSON.parse(localStorage.getItem('uid')));
    }
  },

  methods: {

  },
}
</script>
<style>
.required {
  color: #FF5252;
  font-size: small;
}
</style> 