import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import loginVue from '../views/ingreso/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: loginVue
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin/admin.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/dashboard/dashboard.vue')
      },
      {
        path: '/registro',
        name: 'registro',
        component: () => import('../views/registro/lista/lista.vue')
      },
      {
        path: '/registro/crear',  
        name: 'registro-crear',
        component: () => import('../views/registro/crear/crear.vue') 
      },
      {
        path: '/registro/ver/:id',
        name: 'registro-ver',
        component: () => import('../views/registro/ver/ver.vue')
      },
      {
        path: '/enviados',
        name: 'enviados',
        component: () => import('../views/registro/lista_enviados/lista.vue')
      },
      {
        path: '/bandeja-tecnico',
        name: 'bandeja-tecnico',
        component: () => import('../views/bandeja/lista_tecnico/lista.vue')
      },
      {
        path: '/bandeja',
        name: 'bandeja',
        component: () => import('../views/bandeja/lista/lista.vue')
      },
      {
        path: '/bandeja/ver/:id',
        name: 'bandeja-ver',
        component: () => import('../views/bandeja/ver/ver.vue')
      },
      {
        path: '/inventario-tecnico',
        name: 'inventario-tecnico',
        component: () => import('../views/inventario-tecnico/lista/lista.vue')
      },
      {
        path: '/inventario-tecnico/ver/:id',
        name: 'inventario-tecnico-ver',
        component: () => import('../views/inventario-tecnico/ver/ver.vue')
      },
      /*  PARA CREAR TODO DE REGISTRO HISTORICO */
      {
        path: '/historicos',
        name: 'historicos',
        component: () => import('../views/historicos/lista/lista.vue')
      },
      {
        path: '/historicos/ver/:id',
        name: 'historicos-ver',
        component: () => import('../views/historicos/ver/ver.vue')
      },
      {
        path: '/historicos/crear',  
        name: 'historicos-crear',
        component: () => import('../views/historicos/crear/crear.vue') 
      },
      {
        path: '/inventarios',
        name: 'inventarios',
        component: () => import('../views/inventario/lista/lista.vue')
      },
      {
        path: '/inventarios/ver/:id',
        name: 'inventarios-ver',
        component: () => import('../views/inventario/ver/ver.vue')
      },
     
      /*  PARA CREAR TODO DE ADMINISTRACION */
      {
        path: '/instituciones',
        name: 'instituciones',
        component: () => import('../views/administracion/instituciones/list/list.vue')
      },
      {
        path: '/tipo-proceso',
        name: 'tipo-proceso',
        component: () => import('../views/administracion/tipo-proceso/list/list.vue')
      },
      {
        path: '/area-protegida',
        name: 'area-protegida',
        component: () => import('../views/administracion/area-protegida/list/list.vue')
      },
      {
        path: '/sectores',
        name: 'sectores',
        component: () => import('../views/administracion/sectores/list/list.vue')
      },
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
