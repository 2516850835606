import {  Component } from 'vue-property-decorator';
import axios from 'axios';
import { MensajesGlobales } from '@/shared/utils/mensajes';
import SettingsService from '../../security/auth/settings.service';
import http from "@/http-common";
import Vue from 'vue';

@Component
export default class Login extends Vue {

    user: string = '';
    password: string = '';
    drawer: any = null;
    ocultar = false;
    width = 300;
    msgService: string = "";
    alert = false;
    public url_api = http.defaults.baseURL;
    public $toastr: any;
    public overlay: boolean = false;
    public loading: boolean = false;
    public signUp: boolean = false;
    $refs!: {
        form: HTMLFormElement
    }
    data() {
        return {       textRules: [  v => !!v || 'Campo requerido', ],valid: false,}
    }
    mounted() {
        if (localStorage.getItem('tkn')) {  this.$router.push('/admin'); }
        let uri = window.location.search.substring(1); let params = new URLSearchParams(uri);var desencripta=params.get('token');
        if(params.has('token')){
            let variable = atob(desencripta as any);let uri2 = variable; let params2 = new URLSearchParams(uri2);this.loading = true;
            axios.post(this.url_api+'/sips/ingresar', { "email": params2.get('usuario'), "password": params2.get('password') }, { headers: this.getHeader() })    
            .then((resp: any) => {
                    if (resp.status) {this.goToMain();this.loading = false;this.overlay = false;this.crearLocalStorage(resp);}
                    else {this.$toastr.w('consulta realizada', 'consulta con exito pero algo salio mal en el back');this.loading = false;}
                }).catch(err => {
                 if (err.status != 401) {this.$toastr.e('Datos Incorrectos', 'Usuario o Contraseña incorrecto');this.user = '';this.password = '';
                 } else { this.$toastr.e('Error', MensajesGlobales.ErrorEnServidor);};this.loading = false;
             });
        }
    }
    getHeader = function () {
        return {  'Content-Type': 'application/json'}
    }

    login() { this.loading = true;this.overlay = true;
         axios.post(this.url_api+'/sips/ingresar', { "email": this.user, "password": this.password }, { headers: this.getHeader() })    
        .then((resp: any) => {
                 if (resp.status) {this.goToMain();this.loading = false;this.overlay = false;this.crearLocalStorage(resp);}
                else {this.$toastr.w('consulta realizada', 'consulta con exito pero algo salio mal en el back');this.loading = false;}
            }).catch(err => {
                if (err.status != 401) {
                    this.$toastr.e('Datos Incorrectos', 'Usuario o Contraseña incorrecto');this.user = '';this.password = '';
                } else {
                    this.$toastr.e('Error', MensajesGlobales.ErrorEnServidor);
                }
                this.loading = false;this.overlay = false;
            });
    }
    goToMain() {
        this.$router.push('/admin');
    }
    onValidate() {if (this.$refs.form.validate()) { this.login();};}
    onRegistrar() {   this.$router.push('/register');}
    crearLocalStorage(userData) {
        localStorage.setItem('tkn', userData.data.token);localStorage.setItem('rol', userData.data.rol);localStorage.setItem('cargo', userData.data.persona.cargo);
        localStorage.setItem('persona_id', userData.data.persona.id);localStorage.setItem('entidad_id', userData.data.persona.entidad_id);localStorage.setItem('uid', JSON.stringify(userData.data));SettingsService.setUsuario(userData.data);
    }
}