import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueToastr from 'vue-toastr';
import '@mdi/font/css/materialdesignicons.css';
import VueQRCodeComponent from 'vue-qrcode-component';
Vue.component('qr-code', VueQRCodeComponent);
Vue.config.productionTip = false

Vue.use(VueToastr, {
  defaultStyle: { "opacity": "1"},
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

