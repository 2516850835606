import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        // dark: false,
        themes: {
          light: {
            primary: '#114c84',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#FF5252',
            background: '#f5f5f5'
          },
        },
      },
});
