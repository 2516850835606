export  class MensajesGlobales {

    // Dialogs Confirma Guardar
    public static DialogTitleConfirmSave = "Confirma Guardar";
    public static DialogContentConfirmSave = '¿Desea guardar los datos Registrados?';
    
    // Dialogs Confirma Guardar
    public static DialogTitleConfirmDelete = "Confirma Elimiar Registro";
    public static DialogContentConfirmDelete = '¿Desea eliminar el archivo selecciondo?';

    // Dialogs Notificacion
    public static DialogTitleNotificacion = "Datos Creado Correctamente";
    public static DialogContentNotificacion = 'Se le envio su usuario a su correo electronico personal';


    // Dialogs Confirma Finalizar
    public static DialogTitleConfirmFinalizar = "Confirma Finalizar";
    public static DialogContentConfirmFinalizar = '¿Confirma la finalización de todos los registros?';

    // Consumo de servicios
    public static ErrorEnServidor = "Error de Servicio";
    public static ServicioEjecutadoCorrectamente = "Operación realizada con exito";
    
    // formulario Error
    public static ErrorFormulario = "Error en Formulario";
    public static ErrorDatosFormulario = "Existen datos erroneos o incompletos";

    // error al cargar
    public static ErrorAlCargarFormulario = "Error al cargar el formulario. Intentelo nuevamente";
    public static ErrorAlCargarRegistros = "Error al cargar los registros. Intentelo nuevamente";
    
    // Error al Realizar un solicitud
    public static ErrorAlRealizarAccion = "Error al realizar la operación";

    // Archivos Adjuntos
    public static AdjuntoNoValido = "El formato del archivo no es válido";
    public static NoTieneAdjunto = "Este registro no cuenta con documento adjunto";
    
    // formulario finalizado
    public static FomularioFinalizado = "Se finalizo el registro, Favor de pasar a Recursos humanos con sus documentos originales";

}
